import { useDispatch, useSelector } from "react-redux";
import ReactGA from 'react-ga4'
import HomePublic from '../components/HomePublic'
import Dashboard from '../components/Dashboard'
import { clearUser } from '../features/auth/authSlice'

function Home() {
  const dispatch = useDispatch();
  const { user, isSuccess } = useSelector((state) => state.auth);
  document.body.style.overflow = "auto";

  ReactGA.send({ hitType: "pageview", page: "/home" });

    // If isSuccess is false but there's still a user, clear it.
    if (!isSuccess && user) {
      dispatch(clearUser())
    }

  return (
    <>
      {isSuccess ? ( <Dashboard />) : ( <HomePublic />)}
    </>
  );
}

export default Home;
