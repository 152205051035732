import { useEffect, forwardRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { getBowties, deleteBowtie } from "../features/bowties/bowtieSlice";
import { getSeverities } from "../features/bowties/severitySlice";
import { useNavigate } from "react-router-dom";
import { ListItemSecondaryAction } from "@mui/material";
import { getThreats } from "../features/bowties/threatSlice";
import { getBarriers } from "../features/bowties/barrierSlice";
import { getConsequences } from "../features/bowties/consequenceSlice";
import { getLikelihoods } from "../features/bowties/likelihoodSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyFilesModal() {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess } = useSelector((state) => state.auth);
  const { bowties, isError, message } = useSelector((state) => state.bowties);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

  }, [isError, message, isSuccess, navigate]);

  const handleClickOpen = () => {
    dispatch(getBowties());
    dispatch(getSeverities());
    dispatch(getLikelihoods());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openBowtie = async (bowtieId) => {
    try {
      await Promise.all([
        dispatch(getThreats(bowtieId)),
        dispatch(getBarriers(bowtieId)),
        dispatch(getConsequences(bowtieId)),
      ]);
      //when the above dispatches are complete, navigate to the editor
      navigate(`/editor/${bowtieId}`);
    } catch (error) {
      console.error(error);
    }
    setOpen(false);
  };

  return (
    <div>
      <Typography onClick={handleClickOpen}>My Bowties</Typography>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Open a Bowtie...
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {bowties.map((bowtie) => (
            <div key={bowtie._id}>
              <ListItem button onClick={() => openBowtie(bowtie._id)}>
                <ListItemText
                  primary={bowtie.title}
                  secondary={
                    "Created: " +
                    new Date(bowtie.createdAt).toLocaleString("en-US") +
                    ", Last Edited: " +
                    new Date(bowtie.updatedAt).toLocaleString("en-US")
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => dispatch(deleteBowtie(bowtie._id))}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Dialog>
    </div>
  );
}
