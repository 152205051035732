import { Box, Grid, Paper, Card, CardContent, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ConsequencesProfile from "./ConsequencesProfile";
import HazardsTable from "./HazardsTable";
import ThreatsProfile from "./ThreatsProfile";
import BoltIcon from "@mui/icons-material/Bolt";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import BowtieIconOrange from "./icons/BowtieIconOrange";
import HealingIcon from "@mui/icons-material/Healing";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Dashboard() {
  const { user, isSuccess } = useSelector((state) => state.auth);
  const [dashboard, setDashboard] = useState();
  const [dynamicLikelihood, setDynamicLikelihood] = useState();
  const [dynamicConsequences, setDynamicConsequence] = useState();
  const [mitigationEffectiveness, setMitigationEffectiveness] = useState();
  const [preventionEffectiveness, setPreventionEffectiveness] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess === false) {
      console.log("User not logged in");
      navigate("/login");
    } else {
      fetchDashboardData();
    }
    // eslint-disable-next-line
  }, [user]);

  

  const fetchDashboardData = async () => {
    const {
      inherentRisk,
      barrierCondition,
      barrierEffectiveness,
      inherentLikelihood,
      inherentConsequence,
      threatsProfile,
      consequencesProfile,
    } = await fetch("/api/dashboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }).then((r) => r.json());

    setDashboard({
      inherentRisk,
      barrierCondition,
      barrierEffectiveness,
      inherentLikelihood,
      inherentConsequence,
      threatsProfile,
      consequencesProfile,
    });
  };

  //use effect run that waits for dashboard data to be populated before rendering the calculated liveLikelihood sum
  useEffect(() => {
    if(dashboard && dashboard.threatsProfile){
    try {
      const summedLiveLikelihoods = dashboard.threatsProfile.reduce(function (acc,obj) {
        return acc + obj.liveLikelihood;
      },
      0);
      setDynamicLikelihood(Math.round(summedLiveLikelihoods));
    } catch (error) {
      console.log(error);
    }
  }
    // eslint-disable-next-line
  }, [dashboard]);

  useEffect(() => {
    if(dashboard && dashboard.consequencesProfile){
    try {
      const summedLiveConsequences = dashboard.consequencesProfile.reduce(
        function (acc, obj) {
          return (
            acc +
            obj.severity *
              (obj.barriersEffectiveness  === 100 ?  1 : (1 - obj.barriersEffectiveness / 100)) *
              dynamicLikelihood
          );
        },
        0
      );
      setDynamicConsequence(Math.round(summedLiveConsequences));
    } catch (error) {
      console.log(error);
    }
  }
    // eslint-disable-next-line
  }, [dashboard, dynamicLikelihood]);

  //average preventative barrier effectiveness
  useEffect(() => {
    if(dashboard && dashboard.threatsProfile){
    try {
      const sumPreventionEffectiveness = dashboard.threatsProfile.reduce(
        function (acc, obj) {
          return acc + obj.barriersEffectiveness / 100;
        },
        0
      );
      const averagePreventionEffectiveness =
        sumPreventionEffectiveness / dashboard.threatsProfile.length;
      setPreventionEffectiveness(averagePreventionEffectiveness);
    } catch (error) {
      console.log(error);
    }
  }
  }, [dashboard]);

  //Avergae mitigation barriers' effectiveness
  useEffect(() => {
    if(dashboard && dashboard.consequencesProfile){
    try {
      const sumMitigationEffectiveness = dashboard.consequencesProfile.reduce(
        function (acc, obj) {
          return acc + (1 - obj.barriersEffectiveness / 100);
        },
        0
      );

      //including the absent barriers in the average to account for unmitigated consequences
      const averageMitigationEffectiveness =
        sumMitigationEffectiveness / dashboard.consequencesProfile.length;
      setMitigationEffectiveness(averageMitigationEffectiveness);
    } catch (error) {
      console.log(error);
    }
  }
  }, [dashboard]);

  if (dashboard) {
    return (
      <Box sx={{ flexGrow: 1, margin: 2 }}>
        <Grid
          container
          spacing={3}
          columns={10}
          direction="row"
          alignItems="stretch"
        >
          <Grid item xs={5} md={2}>
            <Card style={{ backgroundColor: "#E0EAFB", height: "100%" }}>
              <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  <BoltIcon
                    sx={{
                      fontSize: 28,
                      color: "#6495ED",
                      verticalAlign: "top",
                    }}
                  />{" "}
                  Threat
                </Typography>
                <Typography sx={{ textAlign: "center" }} color="text.primary">
                  {"Likelihood: "}
                </Typography>
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="h4"
                  color="text.primary"
                >
                  {dashboard.inherentLikelihood}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5} md={2}>
            <Card style={{ backgroundColor: "#FFF7CC", height: "100%" }}>
              <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  <HealthAndSafetyIcon
                    sx={{
                      fontSize: 28,
                      color: "#FFD700",
                      verticalAlign: "top",
                    }}
                  />{" "}
                  Prevention Barriers
                </Typography>
                <Typography sx={{ textAlign: "center" }} color="text.primary">
                  {"Effectiveness: "}
                </Typography>
                <Typography
                  sx={{ textAlign: "center" }}
                  color="text.primary"
                  variant="h4"
                >
                  {preventionEffectiveness
                    ? Math.round(preventionEffectiveness * 100)
                    : ""}
                  %
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5} md={2}>
            <Card style={{ backgroundColor: "#FFEFD1", height: "100%" }}>
              <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  <BowtieIconOrange
                    sx={{ fontSize: 22, verticalAlign: "middle", mb: 0.5 }}
                  />{" "}
                  Event
                </Typography>
                <Typography sx={{ textAlign: "left" }} color="text.primary">
                  {"Dynamic Likelihood: "} {dynamicLikelihood}
                </Typography>
                <Typography sx={{ textAlign: "left" }} color="text.primary">
                  {"Inherent Risk: "} {dashboard.inherentRisk}
                </Typography>
                <Typography sx={{ textAlign: "left" }} color="text.primary">
                  {"Dynamic Risk: "}
                  {dynamicConsequences}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5} md={2}>
            <Card style={{ backgroundColor: "#FFF7CC", height: "100%" }}>
              <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  <HealingIcon
                    sx={{
                      fontSize: 28,
                      color: "#FFD700",
                      verticalAlign: "top",
                    }}
                  />{" "}
                  Mitigation Barriers
                </Typography>
                <Typography sx={{ textAlign: "center" }} color="text.primary">
                  {"Effectiveness: "}
                </Typography>
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="h4"
                  color="text.primary"
                >
                  {mitigationEffectiveness
                    ? Math.round(mitigationEffectiveness * 100)
                    : ""}
                  %
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={5} md={2}>
            <Card style={{ backgroundColor: "#FFDCD6", height: "100%" }}>
              <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  <MinorCrashIcon
                    sx={{
                      fontSize: 28,
                      color: "#FF4E33",
                      verticalAlign: "top",
                    }}
                  />{" "}
                  Consequences
                </Typography>
                <Typography sx={{ textAlign: "center" }} color="text.primary">
                  {"Severity: "}
                </Typography>
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="h4"
                  color="text.primary"
                >
                  {dashboard.inherentConsequence}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={10} md={5}>
            <Item>
              <ThreatsProfile />
            </Item>
          </Grid>

          <Grid item xs={10} md={5}>
            <Item>
              <ConsequencesProfile />
            </Item>
          </Grid>

          <Grid item xs={10}>
            <HazardsTable />
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default Dashboard;
